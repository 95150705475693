<template>
  <b-container fluid>
    <b-row>
      <!-- Invite User To TestSeries -->
      <b-modal
        v-model="showInviteUserToGIDEPrepModal"
        scrollable
        :title="cvModalTitle"
        size="xxl"
        id="inviteModal"
      >
        <InviteUser :propProductCode="GideProducts.TESTSERIES" :propProductName="'TestSeries'" :propOpenedInModal="true" :propCounsellor="userData" @emitCloseInviteUser="emitCloseInviteUser" />
        <template #modal-footer="">
          <b-button size="sm" @click="emitCloseInviteUser()">
            {{cvbtnModalCancel}}
          </b-button>
        </template>
      </b-modal><!-- Invite User To TestSeries -->
      <b-col md="12" class="heading_alignment">
        <iq-card class="p-3">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa fa-solid fa-graduation-cap " aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
              <span class="btn_in_header">
                <b-button variant="primary" @click="goToSubjectModuleConfigs">{{cvbtnSubjectModuleConfig}}</b-button>
                <b-button class="m-2" variant="primary" @click="goToQuizDashboard">{{cvbtnAddNew}}</b-button>
                <b-button class="m-2" variant="primary" @click="showInviteUserToGIDEPrep">{{cvbtnInviteToGIDEPrep}}</b-button>
                <b-button class="m-2" variant="primary" @click="goToGIDEPrepPdtPage">{{cvbtnPdtPage}}</b-button>
              </span>
            </h4>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchtprep_sessionWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->

              <b-col class="col-12 col-sm-4 col-md-3 col-lg-4 mb-2">
                <select v-model="interest_id" class="form-control" @change="setExamNameFilter">
                  <option v-for="(interest_id, index) of examName" :key="(index+1)">
                    {{interest_id.interest_id}}
                  </option>
                </select>
              </b-col>

              <b-col sm="12" md="4" lg="4" xl="4" class="pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="gettprep_sessionDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="tprep_sessionObjList && tprep_sessionObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="tprep_sessionObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>

                  <template v-slot:cell(user_name)="data">
                    <span class="pointer" @click="openUserProfile(data.item.user_id)">{{ data.item.user_name }}</span><br>
                    <span><small>
                      Id:&nbsp;{{ data.item.user_id }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_id)"></i><br>
                      Email:&nbsp;{{ data.item.user_email }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_email)"></i><br>
                      Mobile:&nbsp;{{ data.item.user_mobile }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_email)"></i><br><br>
                      Class&nbsp;{{ data.item.class_name }}<br>
                      {{ data.item.org_name }}<br>
                      {{data.item.org_city ? data.item.org_city : '' }}
                      {{data.item.org_state ? ', ' + data.item.org_state : '' }}
                      {{data.item.org_country ? ', ' + data.item.org_country : '' }}<br>
                    </small></span>
                  </template>

                  <template v-slot:cell(tprep_id)="data">
                    <small class="d-flex">
                      <div>
                        <b-badge  :style="getStateColorCode('interest_id')" class="mt-2 mr-3" >
                          {{data.item.interest_id.split("_")[1]}}
                        </b-badge>
                      </div>
                      <!-- <div v-for="(batch, index) of data.item.quiz_latest_batch_no.split(',')" :key="index" class="pointer"  style="display:flex;align-items:center;" @click="goToTprepQuizDashboard(batch, data.item.interest_id)">
                        <small>
                          <span class="mt-2" >
                            Question Batch No:&nbsp;{{batch.split('-')[0]}}
                          </span> <br/>
                          Id:&nbsp;{{batch.split('-')[1].slice(0,10)}}...&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(tprepId)"></i><br/><br/>
                        </small>
                      </div> -->
                      <small v-if="userData.user_role == 'USERROLE11111'">
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="goToUsersTestPapers(data.item)" title="Goto TestSeries"><i class="fa fa-graduation-cap m-0"></i></b-button>
                      </small>
                      <br>
                    </small>
                  </template>

                  <template v-slot:cell(payment_id)="data">
                    <div v-if="data.item.payment_id">
                      <small v-for="(payId, index) of data.item.payment_id.split(',')" :key="index" class="pointer">Inv:&nbsp;
                        <span class="success-color" @click="goToUserPayments(data.item.user_id)">
                          {{payId}}
                        </span>&nbsp;&nbsp;
                        <i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(payId)"></i><br/>
                        <span>
                          Question Batch No:&nbsp;{{data.item.quiz_latest_batch_no.split(',')[index].split('-')[0]}}<br />
                          Id:&nbsp;{{data.item.quiz_latest_batch_no.split(',')[index].split('-')[1].slice(0,10)}}...&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.quiz_latest_batch_no.split(',')[index].split('-')[1])"></i><br/><br/>
                        </span>
                      </small>
                    </div>
                    <div v-if="data.item.affiliate_data">
                      <small v-for="(aff, index) of data.item.affiliate_data.split(',')" :key="index" class="pointer">
                        <span style="vertical-align: middle; color: #FFF; background: #e5252c !important; padding:5px; border-radius:5px;">Code:&nbsp;{{aff.split("-")[0]}}</span><br/>
                        <span>Counsellor:&nbsp;{{aff.split("-")[1]}}</span><br/>
                        <span>
                          Question Batch No:&nbsp;{{data.item.quiz_latest_batch_no.split(',')[index].split('-')[0]}}<br />
                          Id:&nbsp;{{data.item.quiz_latest_batch_no.split(',')[index].split('-')[1].slice(0,10)}}...&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.quiz_latest_batch_no.split(',')[index].split('-')[1])"></i><br/><br/>
                        </span>
                      </small>
                    </div>
                  </template>

                  <template v-slot:cell(product)="data">
                    <div class="pointer" v-for="(pdt, index) of userSubsPdtMap[data.item.user_id].split(',')" :key="index" @click="goToUserSubscriptions(data.item.user_id)">
                      <b-badge v-if="pdt.length > 0" :style="getproductColorCode()" class="mb-2">
                        {{pdt}}
                      </b-badge><br>
                    </div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="tprep_sessionEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showTprepSessionDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelTprepSessionAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <TprepSessionAdd :propOpenedInModal="true" @emitCloseTprepSessionAddModal="closeTprepSessionAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="tprep_sessionAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeTprepSessionAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelTprepSessionEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <TprepSessionEdit :propOpenedInModal="true" :propTprepSessionObj="tprep_sessionEditObj" @emitCloseTprepSessionEditModal="closeTprepSessionEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="tprep_sessionEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeTprepSessionEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelTprepSessionDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelTprepSessionDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="tprep_sessionDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { TprepSessions } from "../../../FackApi/api/TprepSession.js"
import TprepSessionAdd from "./TprepSessionAdd"
import TprepSessionEdit from "./TprepSessionEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"
import InviteUser from "../../../components/InviteUser.vue"
import userPermission from "../../../Utils/user_permission.js"
import GideProducts from "../../../FackApi/json/GideProducts.json"

export default {
  name: "TestSeriesDashboard",
  components: {
    TprepSessionAdd,
    TprepSessionEdit,
    InviteUser
  },
  data () {
    return {
      apiName: "app_edit",
      cvCardTitle: "TestSeries Admin Dashboard",
      cvbtnSubjectModuleConfig: "TestSeries Configuration",
      cvbtnAddNew: "Quiz Dashboard",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit TestSeries",
      cvAddModalHeader: "Add TestSeries",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "TestSeries",
      showModelTprepSessionAdd: false,
      showModelTprepSessionEdit: false,
      showModelTprepSessionDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "User Name", key: "user_name", class: "text-left align-text-top w-300px", sortable: true },
        { label: "TestSeries Info", key: "tprep_id", class: "text-left align-text-top", sortable: true },
        { label: "Payments", key: "payment_id", class: "text-left align-text-top", sortable: true },
        { label: "Product Subscription ", key: "product", class: "text-left align-text-top", sortable: true }
      ],
      tprep_sessionObjList: [],
      tprep_sessionEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      interest_id: "",
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 200,
      showLoadMoreBtn: true,
      cvModalTitle: "Invite User To TestSeries",
      showInviteUserToGIDEPrepModal: false,
      cvbtnInviteToGIDEPrep: "Invite Student",
      cvbtnPdtPage: "Product Page",
      examName: [],
      GideProducts: GideProducts,
      userSubsPdtMap: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    await this.getDateFilter()
    this.tprepExamNameList()
    this.tprep_sessionList()
  },
  methods: {
    /**
     * Calling Function as per exam name filter update
    */
    async setExamNameFilter () {
      this.tprepExamNameList()
      this.tprep_sessionList()
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * searchtprep_sessionWithParams
     */
    async searchtprep_sessionWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.tprep_sessionList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.tprep_sessionList(true)
    },
    /**
     * goToSubjectModuleConfigs
     */
    goToSubjectModuleConfigs () {
      this.$router.push("/testseries_configuration")
    },
    /**
     * Calling Function as per whereFilter
    */
    async gettprep_sessionDateWise () {
      this.tprep_sessionList()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * tprep_sessionList
     */
    async tprep_sessionList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param
        this.whereFilter.interest_id = this.interest_id
        let tprepSessionListResp = await TprepSessions.tprep_sessionList(this, this.whereFilter)
        if (tprepSessionListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.tprep_sessionObjList = [ ...tprepSessionListResp.resp_data.data ]
          }
          else {
            this.tprep_sessionObjList = [ ...this.tprep_sessionObjList, ...tprepSessionListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.tprep_sessionObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.tprep_sessionObjList.length

          this.userSubsPdtMap = { ...this.userSubsPdtMap, ...tprepSessionListResp.user_product_map }
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = tprepSessionListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at tprep_sessionList() and Exception:", err)
      }
    },

    /**
     * tprepExamNameDistinctList
     */
    async tprepExamNameList () {
      try {
        let tprepSubjectListResp = await TprepSessions.tprep_exam_name_list(this)
        if (tprepSubjectListResp && tprepSubjectListResp.resp_status) {
          this.examName = tprepSubjectListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at qbkCategory() and Exception:", err.message)
      }
    },
    /**
     * tprep_sessionAdd
     */
    tprep_sessionAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/tprep_session_add")
        }
        else {
          this.showModelTprepSessionAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at tprep_sessionAdd() and Exception:", err.message)
      }
    },
    /**
     * tprep_sessionEdit
     */
    tprep_sessionEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/tprep_session_edit/" + this.tprep_sessionEditObj.tps_id)
        }
        else {
          this.tprep_sessionEditObj = item
          this.showModelTprepSessionEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at tprep_sessionEdit() and Exception:", err.message)
      }
    },
    /**
     * showTprepSessionDeleteDialog
     */
    showTprepSessionDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelTprepSessionDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showTprepSessionDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * tprep_sessionDelete
     */
    async tprep_sessionDelete () {
      try {
        let tprepSessionDelResp = await TprepSessions.tprep_sessionDelete(this, this.delObj.tps_id)
        await ApiResponse.responseMessageDisplay(this, tprepSessionDelResp)

        if (tprepSessionDelResp && !tprepSessionDelResp) {
          this.showModelTprepSessionDelete = false
          return false
        }

        let index = this.tprep_sessionObjList.indexOf(this.delObj)
        this.tprep_sessionObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelTprepSessionDelete = false
      }
      catch (err) {
        console.error("Exception occurred at tprep_sessionDelete() and Exception:", err.message)
      }
    },
    /**
     * closeTprepSessionAddModal
     */
    closeTprepSessionAddModal (tprepSessionAddData) {
      try {
        if (tprepSessionAddData) {
          if (this.tprep_sessionObjList && this.tprep_sessionObjList.length >= 1) {
            let tprepSessionObjLength = this.tprep_sessionObjList.length
            let lastId = this.tprep_sessionObjList[tprepSessionObjLength - 1]["id"]
            tprepSessionAddData.id = lastId + 1
          }
          else {
            this.tprep_sessionObjList = []
            tprepSessionAddData.id = 11111
          }

          tprepSessionAddData.created_on = moment()
          this.tprep_sessionObjList.unshift(tprepSessionAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelTprepSessionAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closetprep_sessionAddModal() and Exception:", err.message)
      }
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * goToGIDEPrepPdtPage
     */
    goToGIDEPrepPdtPage () {
      this.$router.push("/testseries_product_main?edit=true")
    },
    /**
     * closeTprepSessionEditModal
     */
    closeTprepSessionEditModal () {
      try {
        this.showModelTprepSessionEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeTprepSessionEditModal() and Exception:", err.message)
      }
    },
    /**
     * goToQuizDashboard
     */
    goToQuizDashboard () {
      this.$router.push("/tprep_quiz_dashboard")
    },
    /**
     * getStateColorCode
     */
    getStateColorCode (batchNo) {
      const batchNoColor = {
        "1": "#3366CC",
        "2": "#E67300",
        "3": "#5574A6"
      }
      if (batchNo == "interest_id") {
        return `vertical-align: middle; color: #FFF; background: #e5252c !important;`
      }
      return `vertical-align: middle; color: #FFF; background: ${batchNoColor[batchNo]} !important;`
    },
    /**
     * goToTprepQuizDashboard
     */
    goToTprepQuizDashboard (batch, examName) {
      this.$router.push(`tprep_quiz_dashboard?batch_no=${batch.split("-")[0]}&interest_id=${examName}`)
    },
    /**
     * openUserProfile
     */
    openUserProfile (userId) {
      let url = `user/${userId}`
      window.open(url, "_blank")
    },
    /**
     * goToUserPayments
     */
    goToUserPayments (userId) {
      let url = `payTransactions_list/${userId}`
      window.open(url, "_blank")
    },
    /**
     * goToUsersTestPapers
     */
    goToUsersTestPapers (user) {
      // #TODOEXTENDFORMOREEXAMS we need to redirect the admin to testseries_exams_all after launching other exams like CUET
      // this.$router.push(`testseries_exams_all?gide_user_id=${user.user_id}`)

      this.$router.push(`subject_modules/TESTSERIES_SAT?gide_user_id=${user.user_id}`)
    },
    /**
     * showInviteUserToGIDEPrep
     */
    showInviteUserToGIDEPrep () {
      this.showInviteUserToGIDEPrepModal = true
    },
    /**
     * emitCloseInviteUser
     */
    emitCloseInviteUser () {
      this.showInviteUserToGIDEPrepModal = false
      this.tprep_sessionList()
    },
    /**
     * goToUserSubscriptions
     */
    goToUserSubscriptions (userid) {
      window.open(`my_billing_history/${userid}`)
    },
    /**
     * getproductColorCode
     */
    getproductColorCode (registrationState = null) {
      if (registrationState && registrationState != 1) {
        return `vertical-align: middle; color: #FFF; background: #FF9900 !important;`
      }
      else {
        return `vertical-align: middle; color: #FFF; background: #e5252c !important;`
      }
    }
  }
}
</script>
<style scoped>
@media (max-width: 1000px){
  span.btn_in_header {
    float: right !important;
    position: unset !important;
  }
}
</style>
